
require("./../../node_modules/@module-federation/nextjs-mf/src/include-defaults");
import { Spinner } from '@/components/shared';
import dynamic from 'next/dynamic';
import React from 'react';

// @ts-ignore: federated module
const AllBrands = dynamic(() => import('beautyFrontendPlp/AllBrands'), { ssr: false, loading: () => <Spinner /> });

const AllBrandsPage = () => <AllBrands />;

export default AllBrandsPage;
